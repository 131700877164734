import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreditCardIcon from '@heroicons/react/24/outline/CreditCardIcon';
import CircleStackIcon from '@heroicons/react/24/outline/CircleStackIcon';
import TitleCard from '../../../components/Cards/TitleCard';
import { NavLink } from 'react-router-dom';
import { fetchraitingmanager } from '../../../app/slices/raitings/maanger';

export default function PersonalManager({ user }) {
    const team = user.includes('КРТ') ? 'lider'
        : user.includes('ТРН') ? 'turan'
            : user.includes('ОЛМ') ? 'fenix'
                : '';

    const dispatch = useDispatch();
    const managers = useSelector(state => state.managerraiting?.data || []);
    const selectedData = managers?.flatMap(el =>
        Array.isArray(el.managers)
            ? el.managers.filter(manager => manager.curator === user)
            : []
    );

    useEffect(() => {
        dispatch(fetchraitingmanager());
    }, [dispatch]);

    const statsData = selectedData.length > 0 ? [
        { title: "Ваша комиссия 7%", value: Number(selectedData[0].comission || 0), icon: <CreditCardIcon className="w-8 h-8" />, description: "за текущий месяц" },
        { title: "Доступные средства", value: Number(selectedData[0].for_withdrawal || 0), icon: <CircleStackIcon className="w-8 h-8" />, description: "для вывода" },
        { title: "Ваш оборот в компании", value: Number(selectedData[0].totalcom || 0), icon: <CreditCardIcon className="w-8 h-8" />, description: "за текущий месяц" },
    ] : [];

    return (
        <div>
            <TitleCard className="w-full h-screen">
                <div className="flex flex-col items-center justify-center gap-5 pt-5">
                    <div className="flex flex-col items-center justify-center gap-5">
                        <div className="w-[100px] h-[100px] rounded-full shadow-xl flex justify-center items-center">
                            <div className="w-[92px] h-[92px] rounded-full shadow-md border flex justify-center items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-20 h-20">
                                    <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                                </svg>
                            </div>
                        </div>
                        <h1 className="font-semibold text-cyan-700">{selectedData.length > 0 ? selectedData[0].curator : 'Загрузка...'}</h1>
                    </div>
                    {selectedData.length > 0 ? (
                        <div className="w-full grid grid-cols-3">
                            <div className="flex flex-col items-center justify-center">
                                <h1 className="font-bold text-cyan-700 text-[30px]">{selectedData[0].buyerLength || 0}</h1>
                                <p className="text-sm text-gray-400">кол-во байер</p>
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <h1 className="font-bold text-cyan-700 text-[30px]">{selectedData[0].order || 0}</h1>
                                <p className="text-sm text-gray-400">кол-во заказов</p>
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <h1 className="font-bold text-cyan-700 text-[30px]">{selectedData[0].allCoeff || 0}</h1>
                                <p className="text-sm text-gray-400">коэффициент</p>
                            </div>
                        </div>
                    ) : (
                        <p className="text-gray-400">Загрузка данных...</p>
                    )}
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 mt-10">
                        {statsData.map((data, index) => (
                            <div key={index} className="stats shadow">
                                <div className="stat">
                                    <div className="stat-figure text-cyan-700">{data.icon}</div>
                                    <div className="stat-title text-[12px]">{data.title}</div>
                                    <div className="stat-value text-cyan-700 text-[25px]">
                                        {data.value} {typeof data.value === 'string' ? '%' : 'сом'}
                                    </div>
                                    <div className="stat-desc">{data.description}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row gap-5 mt-10">
                    <NavLink to={`/add-${team}-otchet`} className="stats shadow">
                        <div className="stat">
                            <div className="stat-title">Создать отчет</div>
                        </div>
                    </NavLink>
                    <NavLink to={`/list-${team}-manager-otchet`} className="stats shadow">
                        <div className="stat">
                            <div className="stat-title">Ежедневные отчеты</div>
                        </div>
                    </NavLink>
                    <NavLink to={`/manager-sredstv-history`} className="stats shadow">
                        <div className="stat">
                            <div className="stat-title">История вывода средств</div>
                        </div>
                    </NavLink>
                    <NavLink to={`/clients-${team}`} className="stats shadow">
                        <div className="stat">
                            <div className="stat-title">Привлеченные клиенты</div>
                        </div>
                    </NavLink>
                </div>
                <div>
                    <table className='table mt-5'>
                        <thead>
                            <tr>
                                <td>Имя</td>
                                <td>Кол-во заказов</td>
                                <td>сумма</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedData.length > 0 ? (
                                    selectedData[0].detail.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>{item.orders}</td>
                                            <td>{item.summa}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">Загрузка данных...</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </TitleCard>
        </div>
    );
}