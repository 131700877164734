import routes from '../routes/sidebar';
import { NavLink, Link, useLocation } from 'react-router-dom';
import SidebarSubmenu from './SidebarSubmenu';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';

function LeftSidebar() {
    const location = useLocation();

    const close = () => {
        document.getElementById('left-sidebar-drawer').click();
    };

    const user = JSON.parse(localStorage.getItem('roles'));

    const filteredRoutes = [
        ...routes.filter(route => {
            if (user?.roles?.role === 'admin2' && (route.name === 'редактор' || route.name === 'отделы')) {
                return false;
            }
            return true;
        }),
        ...(user?.roles?.role === 'admin2'
            ? [
                {
                    path: '/otchety',
                    icon: (
                        <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 6h13M8 12h9m-9 6h13M3 6h.01M3 12h.01M3 18h.01"
                            />
                        </svg>
                    ),
                    name: 'отчёты',
                },
            ]
            : [])
    ];

    return (
        <div className="drawer-side">
            <label htmlFor="left-sidebar-drawer" className="drawer-overlay"></label>
            <ul className="menu pt-2 w-[250px] bg-base-100 text-base-content">
                <button
                    className="btn btn-ghost bg-base-300 btn-circle z-50 top-0 right-0 mt-4 mr-2 absolute lg:hidden"
                    onClick={close}
                >
                    <XMarkIcon className="h-5 inline-block w-5" />
                </button>

                <li className="mb-2 font-semibold text-xl">
                    <Link to={'/'}>
                        <img
                            className="mask mask-squircle w-10"
                            src="/logo.jpg"
                            alt="FR Logo"
                        />
                        Fashion Rynok
                    </Link>
                </li>
                {filteredRoutes.map((route, k) => (
                    <li key={k}>
                        {route.submenu ? (
                            <SidebarSubmenu {...route} />
                        ) : (
                            <NavLink
                                end
                                to={route.path}
                                className={({ isActive }) =>
                                    `${isActive
                                        ? 'font-semibold bg-base-200'
                                        : 'font-normal'
                                    }`
                                }
                            >
                                {route.icon} {route.name}
                                {location.pathname === route.path && (
                                    <span
                                        className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-md bg-primary"
                                        aria-hidden="true"
                                    ></span>
                                )}
                            </NavLink>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default LeftSidebar;