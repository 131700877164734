import Header from "./Header"
import { Route, Routes } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import SuspenseContent from "./SuspenseContent"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from "react"
import Dashboard from "../features/dashboard"
import InstagramSlot from "../features/instagram-slot"
import Team from "../features/telegram-slot"
import Integration from "../features/otdely"
import Charts from "../features/charts"
import DetailList from "../components/list-otchet/detailList"
import DetailListManager from "../components/list-otchet/detailListManager"
import MonacoPage from "../pages/monaco/monaco-page"
import { fetchMonacoDatas } from "../app/slices/data-otchets/monacodatasSlice"
import { fetchTuranDatas } from "../app/slices/data-otchets/turandatasSlice"
import { fetchFenixDatas } from "../app/slices/data-otchets/fenixdatasSlice"
import { fetchFboxDatas } from "../app/slices/data-otchets/fboxDatasSlice"
import { fetchLibertyDatas } from "../app/slices/data-otchets/libertyDatasSlice"
import ListManagerMonaco from "../pages/monaco/otchet/listMahager"
import AddMonacoOtchet from "../pages/monaco/AddMonacoOtchet"
import SearchMonacoList from "../pages/monaco/otchet/searchOtchet"
import OtchetListMonaco from "../pages/monaco/otchet/listOtchet"
import TuranPage from "../pages/turan/turan-page"
import AddTuranOtchet from "../pages/turan/AddTuranOtchet"
import OtchetListTuran from "../pages/turan/otchet/listOtchet"
import SearchTuranList from "../pages/turan/otchet/searchOtchet"
import ListManagerTuran from "../pages/turan/otchet/listMahager"
import FenixPage from "../pages/fenix/fenix-page"
import AddFenixOtchet from "../pages/fenix/AddFenixOtchet"
import OtchetListFenix from "../pages/fenix/otchet/listOtchet"
import SearchFenixList from "../pages/fenix/otchet/searchOtchet"
import ListManagerFenix from "../pages/fenix/otchet/listMahager"
import ItogResult from "../pages/it/global-page"
import SearchItogs from "../pages/it/pages/search-itogs/searchItog"
import EditInstagram from "../pages/editor/editInstagram"
import Access from "../pages/protected/access"
import EditSimPage from "../pages/editor/editSimPage"

import OtchetListLeader from "../pages/leader/otchet/listOtchet"
import SearchLeaderList from "../pages/leader/otchet/searchOtchet"
import LeaderPage from "../pages/leader/leader-page"
import AddLeaderOtchet from "../pages/leader/AddLeaderOtchet"
import ListManagerLeader from "../pages/leader/otchet/listMahager"
import LeaderClients from "../pages/leader/client-page/LeaderClients"
import { fetchLiderDatas } from "../app/slices/data-otchets/liderdatasSlice"

import Otchets from "../pages/fullfilment/components/add-otchet"
import FboxPage from "../pages/fbox/fbox-page"
import AddFboxOtchet from "../pages/fbox/AddFboxOtchet"
import OtchetListFbox from "../pages/fbox/otchet/listOtchet"
import SearchFboxList from "../pages/fbox/otchet/searchOtchet"
import ListManagerFbox from "../pages/fbox/otchet/listMahager"

import FullfilmentPage from "../pages/fullfilment/fullfilmentPage"
import Fullfilment1 from "../pages/fullfilment/pages/fullfilments-1"
import MonacoClients from "../pages/monaco/client-page/MonacoClients"
import FboxClients from "../pages/fbox/client-page/FboxClients"
import FenixClients from "../pages/fenix/client-page/FenixClients"
import TuranClients from "../pages/turan/client-page/TuranClients"
import ITclients from "../pages/it/pages/clients/ITclients"
import SearchClients from "../pages/it/pages/clients/search-clients"
import Fullfilment1List from "../pages/fullfilment/pages/fullfilments-1-list"
import DetailsPage from "../pages/fullfilment/components/detail-page"
import PersonalPage from "../pages/personal-page/personal-page"
import ItManagerPage from "../pages/it/pages/manager/it-manager-page"
import OnlinePage from "../pages/online-page/online-page"
import OnlineCliets from "../pages/online-page/online-clients"
import LibertyPage from "../pages/liberty/liberty-page"
import LibertyClients from "../pages/liberty/client-page/LibertyClients"
import AddLibertyOtchet from "../pages/liberty/AddLibertyOtchet"
import OtchetListLiberty from "../pages/liberty/otchet/listOtchet"
import ManagerSredstv from "../pages/personal-page/manager/manager-sredstv"
import ListManagerLiberty from "../pages/liberty/otchet/listMahager"
import DetailItogIT from "../pages/it/components/detail-itog"
import SchetLeader from "../pages/leader/otchet/Schet-leader"
import OnlineOtchets from "../pages/online-page/online-otchets"
import DetailItogOnline from "../pages/online-page/components/detail-otchet"
import SchetMonaco from "../pages/monaco/otchet/Schet-monaco"
import SchetFbox from "../pages/fbox/otchet/Schet-fbox"
import SchetFenix from "../pages/fenix/otchet/Schet-fenix"
import SchetTuran from "../pages/turan/otchet/Schet-turan"
import SchetLiberty from "../pages/liberty/otchet/Schet-liberty"
import SearchLibertyList from "../pages/liberty/otchet/searchOtchet"
import ParishesPage from "../pages/it/pages/parishes/parishes-page"
import Buyers from "../pages/it/pages/buyers/buyers"
import Smanager from "../pages/it/pages/smanager"
import IncomingAndOutgoing from "../pages/it/pages/incoming-and-outgoing"
import CurrentMonthExpencesTwo from "../pages/it/pages/incoming-and-outgoing/pages/current-month-expences"
import HistoryExpencesTwo from "../pages/it/pages/incoming-and-outgoing/pages/history-expences"
import HistoryExpencesDetailsTwo from "../pages/it/pages/incoming-and-outgoing/pages/components/historyExpences"
import CurrentMonthProfitTwo from "../pages/it/pages/incoming-and-outgoing/pages/current-month-profit"
import HistoryProfitTwo from "../pages/it/pages/incoming-and-outgoing/pages/history-profit"
import HistoryProfitDetailsTwo from "../pages/it/pages/incoming-and-outgoing/pages/components/historyProfit"
import AccountsCreate from "../pages/editor/accounts"
import Admin2 from "../pages/admin/admin2"
import DetailItogAdmin from "../pages/admin/detailAdmin"

const Page404 = lazy(() => import('../pages/protected/404'))

function PageContent() {
    const mainContentRef = useRef(null);
    const { pageTitle } = useSelector(state => state.header)
    const datasItog = useSelector((state) => state.liderDatas);
    const datasItogMonaco = useSelector((state) => state.monacoDatas);
    const datasItogTuran = useSelector((state) => state.turanDatas);
    const datasItogFenix = useSelector((state) => state.fenixDatas);
    const datasItogFbox = useSelector((state) => state.fboxDatas);
    const datasItogLiberty = useSelector((state) => state.libertyDatas);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchMonacoDatas())
        dispatch(fetchTuranDatas())
        dispatch(fetchFenixDatas())
        dispatch(fetchFboxDatas())
        dispatch(fetchLiderDatas())
        dispatch(fetchLibertyDatas())
    }, [dispatch]);

    useEffect(() => {
        mainContentRef.current.scroll({
            top: 0,
            behavior: "smooth"
        });
    }, [pageTitle])

    return (
        <div className="drawer-content flex flex-col ">
            <Header />
            <main className="flex-1 overflow-y-auto pt-8 lg:px-6 px-3  bg-base-200" ref={mainContentRef}>
                <Suspense fallback={<SuspenseContent />}>
                    <Routes>
                        <Route path="/instagram-slot" element={<InstagramSlot />} />
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/telegram-slot" element={<Team />} />
                        <Route path="/otdely" element={<Integration />} />
                        <Route path="/analytics" element={<Charts />} />
                        <Route path="/404" element={<Page404 />} />
                        <Route path="/it-manager-all" element={<ItManagerPage />} />
                        <Route path="/accounts-create" element={<AccountsCreate />} />

                        <Route path='/leader-page' element={<LeaderPage />} />
                        <Route path='/clients-lider' element={<LeaderClients />} />
                        <Route path='/add-lider-otchet' element={<AddLeaderOtchet />} />
                        <Route path='/list-lider-otchet' element={<OtchetListLeader datas={datasItog.data} />} />
                        <Route path='/leader-list-search' element={<SearchLeaderList datas={datasItog.data} />} />
                        <Route path="/detail-list-leader/:id" element={<DetailList datas={datasItog.data} />} />
                        <Route path="/list-lider-manager-otchet" element={<ListManagerLeader datas={datasItog.data} />} />
                        <Route path="/detail-list-manager/leader/:id" element={<DetailListManager updateApi='updateDatalider' simApi='simcardliders' datas={datasItog.data} />} />

                        {/* <Route path="/monaco-page" element={<MonacoPage />} />
                        <Route path='/clients-monaco' element={<MonacoClients />} />
                        <Route path='/add-monaco-otchet' element={<AddMonacoOtchet />} />
                        <Route path='/list-monaco-otchet' element={<OtchetListMonaco datas={datasItogMonaco.data} />} />
                        <Route path='/monaco-list-search' element={<SearchMonacoList datas={datasItogMonaco.data} />} />
                        <Route path="/detail-list-monaco/:id" element={<DetailList datas={datasItogMonaco.data} />} />
                        <Route path="/list-monaco-manager-otchet" element={<ListManagerMonaco datas={datasItogMonaco.data} />} />
                        <Route path="/detail-list-manager/monaco/:id" element={<DetailListManager updateApi='updateDatamonaco' simApi='simcardmonacos' datas={datasItogMonaco.data} />} />
                        <Route path="/schet-monaco" element={<SchetMonaco />} /> */}

                        {/* <Route path="/fbox-page" element={<FboxPage />} />
                        <Route path='/clients-fbox' element={<FboxClients />} />
                        <Route path='/add-fbox-otchet' element={<AddFboxOtchet />} />
                        <Route path='/list-fbox-otchet' element={<OtchetListFbox datas={datasItogFbox.data} />} />
                        <Route path='/fbox-list-search' element={<SearchFboxList datas={datasItogFbox.data} />} />
                        <Route path="/detail-list-fbox/:id" element={<DetailList datas={datasItogFbox.data} />} />
                        <Route path="/list-fbox-manager-otchet" element={<ListManagerFbox datas={datasItogFbox.data} />} />
                        <Route path="/detail-list-manager/fbox/:id" element={<DetailListManager updateApi='updateDatafbox' simApi='simCardNewOtdel' datas={datasItogFbox.data} />} />
                        <Route path="/schet-fbox" element={<SchetFbox />} /> */}

                        <Route path="/fenix-page" element={<FenixPage />} />
                        <Route path='/clients-fenix' element={<FenixClients />} />
                        <Route path='/add-fenix-otchet' element={<AddFenixOtchet />} />
                        <Route path='/list-fenix-otchet' element={<OtchetListFenix datas={datasItogFenix.data} />} />
                        <Route path='/fenix-list-search' element={<SearchFenixList datas={datasItogFenix.data} />} />
                        <Route path="/detail-list-fenix/:id" element={<DetailList datas={datasItogFenix.data} />} />
                        <Route path="/list-fenix-manager-otchet" element={<ListManagerFenix datas={datasItogFenix.data} />} />
                        <Route path="/detail-list-manager/fenix/:id" element={<DetailListManager updateApi='updateDatafenix' simApi='simcardfenixes' datas={datasItogFenix.data} />} />
                        <Route path="/schet-fenix" element={<SchetFenix />} />

                        <Route path="/turan-page" element={<TuranPage />} />
                        <Route path='/clients-turan' element={<TuranClients />} />
                        <Route path='/add-turan-otchet' element={<AddTuranOtchet />} />
                        <Route path='/list-turan-otchet' element={<OtchetListTuran datas={datasItogTuran.data} />} />
                        <Route path='/turan-list-search' element={<SearchTuranList datas={datasItogTuran.data} />} />
                        <Route path="/detail-list-turan/:id" element={<DetailList datas={datasItogTuran.data} />} />
                        <Route path="/list-turan-manager-otchet" element={<ListManagerTuran datas={datasItogTuran.data} />} />
                        <Route path="/detail-list-manager/turan/:id" element={<DetailListManager updateApi='updateDataturan' simApi='simcardturans' datas={datasItogTuran.data} />} />
                        <Route path="/schet-turan" element={<SchetTuran />} />

                        {/* <Route path="/liberty-page" element={<LibertyPage />} />
                        <Route path='/clients-liberty' element={<LibertyClients />} />
                        <Route path='/add-liberty-otchet' element={<AddLibertyOtchet />} />
                        <Route path='/list-liberty-otchet' element={<OtchetListLiberty datas={datasItogLiberty.data} />} />
                        <Route path='/liberty-list-search' element={<SearchLibertyList datas={datasItogLiberty.data} />} />
                        <Route path="/detail-list-liberty/:id" element={<DetailList datas={datasItogLiberty.data} />} />
                        <Route path="/list-liberty-manager-otchet" element={<ListManagerLiberty datas={datasItogLiberty.data} />} />
                        <Route path="/detail-list-manager/liberty/:id" element={<DetailListManager updateApi='updateDataliberty' simApi='simcardliberty' datas={datasItogLiberty.data} />} />
                        <Route path="/schet-liberty" element={<SchetLiberty />} /> */}

                        <Route path="/it-page" element={<ItogResult />} />
                        <Route path="/clients-all" element={<ITclients />} />
                        <Route path="/search-itogs" element={<SearchItogs />} />
                        <Route path="/search-client-otchet" element={<SearchClients />} />
                        <Route path="/instaslot-edit" element={<EditInstagram />} />
                        <Route path="/personal-pages" element={<PersonalPage />} />
                        <Route path="/manager-sredstv-history" element={<ManagerSredstv />} />
                        <Route path="/detail-allitog/:id" element={<DetailItogIT />} />
                        <Route path="/schet-lider" element={<SchetLeader />} />
                        <Route path="/it-parishes" element={<ParishesPage />} />
                        <Route path="/it-buyers" element={<Buyers />} />
                        <Route path="/it-smanager" element={<Smanager />} />
                        <Route path="/incoming-and-outgoing" element={<IncomingAndOutgoing />} />
                        <Route path="/outgoing" element={<CurrentMonthExpencesTwo />} />
                        <Route path="/outgoing-archive" element={<HistoryExpencesTwo />} />
                        <Route path="/expences-details/:id" element={<HistoryExpencesDetailsTwo />} />
                        <Route path="/ingoing" element={<CurrentMonthProfitTwo />} />
                        <Route path="/ingoing-archive" element={<HistoryProfitTwo />} />
                        <Route path="/two/profit-details/:id" element={<HistoryProfitDetailsTwo />} />


                        <Route path="/online-page" element={<OnlinePage />} />
                        <Route path="/online-clients" element={<OnlineCliets />} />
                        <Route path="/online-otchet-search" element={<OnlineOtchets />} />
                        <Route path="/detail-online-otchet/:id" element={<DetailItogOnline />} />
                        <Route path="/otchety" element={<Admin2 />} />
                        <Route path='/detail-admin-otchet/:id' element={<DetailItogAdmin />} />


                        <Route path='/sim-cards' element={<EditSimPage />} />
                        <Route path='/fullfillment-page' element={<FullfilmentPage />} />

                        <Route path="*" element={<Page404 />} />
                        <Route path="/access" element={<Access />} />

                        <Route path="/fullfilment-page-1" element={<Fullfilment1 />} />
                        <Route path="/list-otchet-full1" element={<Fullfilment1List />} />
                        <Route path="/detail-fulfilment-otchet-1/:id" element={<DetailsPage link={'1'} />} />
                        <Route path="/add-otchet-full1" element={<Otchets api={'1'} />} />
                    </Routes>
                </Suspense>
                <div className="h-16"></div>
            </main>
        </div>
    )
}


export default PageContent