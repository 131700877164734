import React, { useEffect } from 'react'
import ListManager from '../../../components/list-otchet/list-manager'
import { Navigate } from 'react-router-dom'
import { fetchFenixDatas } from '../../../app/slices/data-otchets/fenixdatasSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function ListManagerFenix() {

    const datasItogFenix = useSelector((state) => state.fenixDatas.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFenixDatas())
    }, [dispatch]);

    const requiredRoles = [
        'root','root.ilyas'
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if ((roles && hasAccess(roles.roles.role, requiredRoles) || roles.roles.role.includes('ВМ ОЛМ'))) {
        return (
            <div>
                <ListManager datas={datasItogFenix} api='fenix' />
            </div>
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}