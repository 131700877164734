import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import TitleCard from '../../../../components/Cards/TitleCard';
import { fetchLiderDatas } from '../../../../app/slices/data-otchets/liderdatasSlice'
import { fetchMonacoDatas } from '../../../../app/slices/data-otchets/monacodatasSlice'
import { fetchTuranDatas } from '../../../../app/slices/data-otchets/turandatasSlice'
import { fetchFenixDatas } from '../../../../app/slices/data-otchets/fenixdatasSlice'
import { fetchFboxDatas } from '../../../../app/slices/data-otchets/fboxDatasSlice'
import { fetchLibertyDatas } from '../../../../app/slices/data-otchets/libertyDatasSlice'
import { useDispatch, useSelector } from 'react-redux';
import DateSearch from '../../components/date-search';

export default function SearchItogs() {

    const [responseData, setResponseData] = useState([]);
    const [selectValue, setSelectValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch()

    const lider = useSelector((state) => state.liderDatas.data);
    const monaco = useSelector((state) => state.monacoDatas.data);
    const turan = useSelector((state) => state.turanDatas.data);
    const fenix = useSelector((state) => state.fenixDatas.data);
    const liberty = useSelector((state) => state.libertyDatas.data);

    useEffect(() => {
        if (selectValue === "1") {
            setResponseData(lider)
        } else if (selectValue === "2") {
            setResponseData(monaco)
        } else if (selectValue === "3") {
            setResponseData(turan)
        } else if (selectValue === "4") {
            setResponseData(fenix)
        } else if (selectValue === "6") {
            setResponseData(liberty)
        } else if (selectValue === "0") {
            setResponseData([])
        }
    }, [selectValue])

    useEffect(() => {
        dispatch(fetchLiderDatas)
        dispatch(fetchMonacoDatas)
        dispatch(fetchTuranDatas)
        dispatch(fetchFenixDatas)
        dispatch(fetchFboxDatas)
        dispatch(fetchLibertyDatas)
    }, [dispatch]);

    return (
        <div>
            {
                typeof lider === 'object' ? (<TitleCard>
                    <div className='flex lg:items-center mb-10 lg:flex-row flex-col gap-3'>
                        Выберите команду:
                        <select
                            className='bg-inherit outline-none border rounded-md p-1'
                            value={selectValue}
                            onChange={(e) => setSelectValue(e.target.value)}
                        >
                            <option value="0">Не выбрано</option>
                            <option value="1">Лидер</option>
                            <option value="2">Монако</option>
                            <option value="3">Туран</option>
                            <option value="4">Ильяс</option>
                            <option value="6">Liberty</option>
                        </select>
                        Введите дату:
                        <input
                            className='bg-inherit outline-none border rounded-md p-1'
                            placeholder='14.10.2023'
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>
                    <DateSearch data={responseData} searchValue={searchValue} />
                </TitleCard>) : <div>Загрузка...</div>
            }
        </div>
    )
}