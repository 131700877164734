import React from 'react'
import ListManager from '../../../components/list-otchet/list-manager'
import { Navigate } from 'react-router-dom'

export default function ListManagerLeader({ datas }) {

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const requiredRoles = [
        'root', 'root.kairat'
    ];

    const roles = JSON.parse(localStorage.getItem('roles'));

    if ((roles && hasAccess(roles.roles.role, requiredRoles))
        || roles.roles.role.includes('ВМ КРТ')) {
        return (
            <div>
                <ListManager datas={datas} api='leader' />
            </div>
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}