import TitleCard from "../../../components/Cards/TitleCard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchraitingmanager } from "../../../app/slices/raitings/maanger";

function StaticManagers() {

    const managers = useSelector(state => state.managerraiting.data);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchraitingmanager());
    }, []);

    const currentDate = new Date().toISOString().slice(0, 7);

    let data = managers
        .filter(item => item.datas === currentDate)
        .flatMap(item => item.managers)
        .sort((a, b) => {
            const coeffA = parseFloat(a.allCoeff) || 0;
            const coeffB = parseFloat(b.allCoeff) || 0;
            return coeffB - coeffA;
        });

    const filteredData = data.filter(item =>
        item.curator && !item.curator.includes('ВМ') && !item.curator.includes("Уулболсун Мамытбекова")
    );

    return (
        <TitleCard title={"Рейтинг старших среди отделов"}>
            <h1 className="mt-5 mb-5">за текущий месяц</h1>
            <h1 className="lg:text-[11px] text-[9px] mb-2">формула: коефф = (касса / кол-во байер) + (кол-во заказов / кол-во байер)</h1>
            <div className="overflow-x-auto">
                <table className="w-full lg:text-[12px] text-[9px]">
                    <thead>
                        <tr className="bg-[#ccc]/30 dark:bg-black/20">
                            <th className="border p-2">топ</th>
                            <th className="border p-2">имя</th>
                            <th className="border p-2">кол-во байеров</th>
                            <th className="border p-2">коефф</th>
                            <th className="border p-2">д/р</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item, index) => (
                            <tr key={item._id}>
                                <td className="border p-2">{index + 1}</td>
                                <td className="border lg:p-3 p-2 w-full">{item.curator}</td>
                                <td className="border p-2 text-center">{item.buyerLength}</td>
                                <td className="border p-2 text-center">{item.allCoeff}</td>
                                <td className="border p-2 text-center text-[6px]">{item.data_register}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </TitleCard>
    );
}

export default StaticManagers;
