import { createSlice } from "@reduxjs/toolkit";
import { api } from "../../../Api";

export const managerSlice = createSlice({
    name: 'maanger-raiting',
    initialState: {
        data: [],
    },
    reducers: {
        fetchDataSuccess: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { fetchDataSuccess } = managerSlice.actions;

export const fetchraitingmanager = () => {
    return async (dispatch) => {
        try {
            const response = await api.get('/raiting-manager');
            const currentDate = new Date().toISOString().slice(0, 7);
            const datas = response.data.filter(item => item.datas === currentDate);
            dispatch(fetchDataSuccess(datas));
        } catch (error) {
            console.log(error);
        }
    };
};

export default managerSlice.reducer;