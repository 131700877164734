import { useDispatch, useSelector } from "react-redux";
import TitleCard from "../../../components/Cards/TitleCard";
import { fetchraitingmanager } from "../../../app/slices/itogs/raitingmanager";
import { useEffect } from "react";

function StaticManagersLastMonth() {

    const dispatch = useDispatch();
    const managerData = useSelector((state) => state.raitingmanager.datasItog); 

    useEffect(() => {
        dispatch(fetchraitingmanager());
    }, [dispatch]);

    const currentDate = new Date();
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1); 
    const previousMonthFormatted = `${previousMonth.getFullYear()}-${previousMonth.getMonth() + 1}`; 

    const lastMonthData = managerData ? managerData.find(item => item.datas === previousMonthFormatted) : null;

    return (
        <TitleCard title={"Рейтинг старших среди отделов"}>
            <h1 className="mt-5 mb-5">за предыдущий месяц</h1>
            <h1 className="lg:text-[11px] text-[9px] mb-2">формула: коефф = (оборот / кол-во байер) + (кол-во заказов / кол-во байер)</h1>
            <div className="overflow-x-auto">
                <table className="w-full lg:text-[12px] text-[9px]">
                    <thead>
                        <tr className="bg-[#ccc]/30 dark:bg-black/20">
                            <th className="border p-2">топ</th>
                            <th className="border p-2">имя</th>
                            <th className="border p-2">кол-во байеров</th>
                            <th className="border p-2">коефф</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lastMonthData && [...lastMonthData.managers]
                            .sort((a, b) => parseFloat(b.allCoeff) - parseFloat(a.allCoeff))
                            .filter(cur => !cur.curator.includes("ВМ"))
                            .map((manager, index) => {
                                const fixedcoeff = parseFloat(manager.allCoeff).toFixed(2);
                                return (
                                    <tr key={index}>
                                        <td className='border p-2'>{index + 1}</td>
                                        <td className="border lg:p-3 p-2 w-full">{manager.curator}</td>
                                        <td className="border p-2 text-center">{manager.buyerLength}</td>
                                        <td className="border p-2 text-center">{fixedcoeff}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </TitleCard>
    );
}

export default StaticManagersLastMonth;
