import React from 'react'
import Access from '../../protected/access';
import ListOtchetfull from '../components/list-otchet';

export default function Fullfilment1List() {

    const requiredRoles = [
        'root'
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));


        return (
            <div>
                <ListOtchetfull link={'1'} />
            </div>
        )
    } 