import React from 'react'
import TitleCard from '../../components/Cards/TitleCard'
import { NavLink } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

export default function EditSimPage() {

    const requiredRoles = [
        'root', 'root.sim'
    ];

    function hasAccess(userRole, requiredRoles) {
        return requiredRoles.includes(userRole);
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    if (roles && hasAccess(roles.roles.role, requiredRoles)) {
        return (
            <div className='flex gap-5 lg:flex-row flex-col flex-wrap'>
                <TitleCard title={'SIM карты байеров и старших менеджеров'}>
                    <div className='flex gap-3'>
                        <NavLink to='/simcard-lider' className='w-20 hover:scale-90 rounded-md border p-2'>Кайрат</NavLink>
                        <NavLink to='/simcard-turan' className='w-20 hover:scale-90 rounded-md border p-2'>Туран</NavLink>
                        <NavLink to='/simcard-fenix' className='w-20 hover:scale-90 rounded-md border p-2'>Ильяс</NavLink>
                        <NavLink to='/simcard-managers' className='w-40 hover:scale-90 rounded-md border p-2'>Старшие менеджеры</NavLink>
                    </div>
                </TitleCard>
                <TitleCard title={'SIM карты Старших админов и помощников'}>
                    <div className='flex gap-3'>
                        <NavLink to='/simcard-logist-lider' className='w-20 hover:scale-90 rounded-md border p-2'>Кайрат</NavLink>
                        <NavLink to='/simcard-logist-turan' className='w-20 hover:scale-90 rounded-md border p-2'>Туран</NavLink>
                        <NavLink to='/simcard-logist-fenix' className='w-20 hover:scale-90 rounded-md border p-2'>Ильяс</NavLink>
                    </div>
                </TitleCard>
                <TitleCard title={'SIM карты IT-отдел'}>
                    <div className='flex gap-3'>
                        <NavLink to='/simcard-it' className='w-30 hover:scale-90 rounded-md border p-2'>Головной отдел</NavLink>
                    </div>
                </TitleCard>
            </div>
        )
    } else {
        return <Navigate to="/access" replace />;
    }
}