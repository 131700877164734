import React, { useEffect } from 'react'
import TitleCard from '../../components/Cards/TitleCard';
import TableItogOnline from '../online-page/components/table-otchet';
import { useDispatch, useSelector } from 'react-redux';
import { fetchitogs } from '../../app/slices/itogs/itogsSlice';
import TableItogAdmin from './tableItogAdmin';
import SearchItogs from '../it/pages/search-itogs/searchItog';

export default function Admin2() {
    const itogs = useSelector(({ itogs }) => itogs.datasItog.otdel)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchitogs());
    }, [dispatch]);

    return (
        <div>
            <SearchItogs />
            {
                typeof itogs === 'object' ? (
                    <div className=' lg:grid lg:grid-cols-2 mt-5 gap-5'>
                        <TitleCard title={'лидер'}>
                            <TableItogAdmin datas={itogs.lider} />
                        </TitleCard>
                        <TitleCard title={'монако'}>
                            <TableItogAdmin datas={itogs.monaco} />
                        </TitleCard>
                        <TitleCard title={'ильяс'}>
                            <TableItogAdmin datas={itogs.fenix} />
                        </TitleCard>
                        <TitleCard title={'туран'}>
                            <TableItogAdmin datas={itogs.turan} />
                        </TitleCard>
                        <TitleCard title={'liberty'}>
                            <TableItogAdmin datas={itogs.liberty} />
                        </TitleCard>
                    </div>
                ) : 'Загрузка...'
            }
        </div>
    )
}
