import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchLiderDatas } from '../../app/slices/data-otchets/liderdatasSlice';
import { fetchMonacoDatas } from '../../app/slices/data-otchets/monacodatasSlice';
import { fetchTuranDatas } from '../../app/slices/data-otchets/turandatasSlice';
import { fetchFenixDatas } from '../../app/slices/data-otchets/fenixdatasSlice';
import { fetchFboxDatas } from '../../app/slices/data-otchets/fboxDatasSlice';
import { fetchLibertyDatas } from '../../app/slices/data-otchets/libertyDatasSlice';
import { useParams } from 'react-router-dom';
import PercentUI from '../components/UI/percent';


export default function DetailItogAdmin() {
    const { id } = useParams()

    const dispatch = useDispatch()

    const lider = useSelector((state) => state.liderDatas.data);
    const monaco = useSelector((state) => state.monacoDatas.data);
    const turan = useSelector((state) => state.turanDatas.data);
    const fenix = useSelector((state) => state.fenixDatas.data);
    const fbox = useSelector((state) => state.fboxDatas.data);
    const liberty = useSelector((state) => state.libertyDatas.data);

    React.useEffect(() => {
        dispatch(fetchLiderDatas)
        dispatch(fetchMonacoDatas)
        dispatch(fetchTuranDatas)
        dispatch(fetchFenixDatas)
        dispatch(fetchFboxDatas)
        dispatch(fetchLibertyDatas)
    }, [dispatch]);

    const datas = [...lider, ...monaco, ...turan, ...fenix, ...fbox, ...liberty]
    const finedData = datas.find(el => el._id === id)

    return (
        <div className=''>
            <PercentUI />
            <>
                <table className='"lg:min-w-full bg-white w-full lg:text-[11px] text-[5px]  text-center'>
                    <thead className='bg-gradient-to-r from-blue-500 to-purple-500 text-white'>
                        <tr>
                            <th className="lg:py-1 lg:px-3 border" rowSpan="3">№</th>
                            <th className="lg:py-1 lg:px-3 border" colSpan="3">Комиссия</th>
                            <th className="lg:py-1 lg:px-3 border" rowSpan="3">СМ</th>
                            <th className="lg:py-1 lg:px-3 border" rowSpan="2">100%</th>
                            <th className="lg:py-1 lg:px-3 border" rowSpan="2">40%</th>
                            <th className="lg:py-1 lg:px-3 border" rowSpan="2">20%</th>
                            <th className="lg:py-1 lg:px-3 border" rowSpan="2">40%</th>
                        </tr>
                        <tr>
                            <th className="lg:py-1 lg:px-2 border lg:w-[200px]">Имя и Город</th>
                            <th className="lg:py-1 lg:px-2 border">Админ</th>
                            <th className="lg:py-1 lg:px-2 border">байер</th>
                        </tr>
                    </thead>
                    {finedData.otchet.map((elem, index) => {
                        const shouldRenderItem =
                            elem.sity !== '' &&
                            elem.comPersent100 !== 0;
                        const isOdd = index % 2 === 1;
                        if (shouldRenderItem) {
                            return (
                                <tbody key={elem._id}>
                                    <tr>
                                        <td className="border lg:py-1 lg:px-3" rowSpan="2">
                                            {index}
                                        </td>
                                        <td className={isOdd ? "border lg:py-1 lg:px-3 text-center" : 'p-1 text-center border lg:py-1 lg:px-3'} rowSpan="2">
                                            {elem.sity}
                                        </td>
                                        <td className={isOdd ? "border lg:py-1 lg:px-3 text-center" : 'p-1 text-center border lg:py-1 lg:px-3'} rowSpan="2">
                                            {elem.admin}
                                        </td>
                                        <td className={isOdd ? "border lg:py-1 lg:px-3 text-center" : 'p-1 text-center border lg:py-1 lg:px-3'} rowSpan="2">
                                            {elem.buyer}
                                        </td>
                                        <td className={isOdd ? "border lg:py-1 lg:px-3 text-center" : 'p-1 text-center border lg:py-1 lg:px-3'} rowSpan="2">
                                            {elem.sm === 0 ? 1 : elem.sm}
                                        </td>
                                        <td className={isOdd ? "border lg:py-1 lg:px-3 text-center" : 'p-1 text-center border lg:py-1 lg:px-3'}>
                                            {elem.comPersent100}
                                        </td>
                                        <td className={isOdd ? "border lg:py-1 lg:px-3 text-center" : 'p-1 text-center border lg:py-1 lg:px-3'}>
                                            {elem.comPersent2}
                                        </td>
                                        <td className={isOdd ? "border lg:py-1 lg:px-3 text-center" : 'p-1 text-center border lg:py-1 lg:px-3'}>
                                            {elem.comPersent3}
                                        </td>
                                        <td className={isOdd ? "border lg:py-1 lg:px-3 text-center" : 'p-1 text-center border lg:py-1 lg:px-3'}>
                                            {elem.comPersent4}
                                        </td>
                                    </tr>
                                </tbody>
                            )
                        }
                    }
                    )}
                </table>
            </>
        </div>
    )
}